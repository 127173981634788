<template>
    <div>
		<b-modal ref="modalRelanceCentre" size="lg" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("monte.relance_centre") }}
				</template>
			</template>

			<div class="col-12">
				<div class="form-group">
					<label for="modeltype_label">{{ $t('model.modeltype_label') }} *</label>
					<e-select
						v-model="template"
						id="model_id"
						track-by="model_label"
						label="model_label"
						:placeholder="labelTitleTemplate"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:options="templates"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="d-flex">
					<b-form-radio v-model="type_send" value="print" class="mr-2">{{ $t('global.print') }}</b-form-radio>
					<b-form-radio v-model="type_send" value="mail" class="mr-2 mb-2">{{ $t('support.email') }}</b-form-radio>
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("global.print") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Contract from "@/mixins/Contract.js"
import { EventBus } from "EventBus"
import Model from "@/mixins/Model.js"

export default {
	name: "modalRelanceCentre",
	mixins: [Contract, Model],
	data () {
		return {
			templates: [],
			template: null,
			processing: false,
			tiers_id: null,
			contracts_ids: null,
			type_send: 'print',

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleFormat: this.getTrad("horse.search_format"),
			labelTitleTemplate: this.getTrad("horse.search_doc")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
			this.template = null
			this.templates = await this.loadAllModel(0, ['relance_centre'])
		},
		openModal(tiers_id, contracts_ids) {
			this.tiers_id = tiers_id
			this.contracts_ids = contracts_ids
			this.$refs.modalRelanceCentre.show()
		},
		closeModal() {
			this.$refs.modalRelanceCentre.hide()
		},
		async checkForm() {
			if(this.template) {
				this.processing = true
				this.printRelanceCentre(this.tiers_id, this.template.model_id, this.contracts_ids, this.type_send)
				.then(() => {
					this.processing = false
					EventBus.$emit('TableAction::stopSpin')
					if(this.type_send == 'mail') {
						this.successToast('gynecologie.send_dg_success')
					}
                    this.closeModal()
				})
			}
			else {
				this.failureToast()
			}
		}
	}
}
</script>